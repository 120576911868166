import React from 'react';
import logo from './logo.svg';
import './App.css';
import Playlist from './components/Playlist/Playlist';
import SpotifyWebPlayer from 'react-spotify-web-playback/lib';
import { SpotifyService } from './services/SpotifyService';
import LightControl from './components/LightControl/LightControl';
import io from 'socket.io-client';

// function App() {
export class App extends React.Component<{}, {}> {

  private socket = io("http://localhost:3000", { transports: ['websocket'] });

  constructor(props: {}) {
    super(props);

    this.socket.on('buttonClicked', function (data) {
      console.log(data.pin);

      document.dispatchEvent(new CustomEvent("amx-button-click", {
        detail: {
            key: data.pin
        }
      }));
    });
    
    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    console.log(`App - componentDidMount - start - ${new Date()}`);

    const url: URL = new URL(window.location.href);
  
    if (url.searchParams.has("code") && url.searchParams.get("state") === "0123456789012345") {
      await SpotifyService.instance.loadAccessTokens(url.searchParams.get("code") as string);
    }

    this.setState({});
  }

  public render(): React.ReactElement<{}> {
    const url: URL = new URL(window.location.href);
    const showPlayer: boolean = url.searchParams.get("showPlayer") === "1";

    return (
      <div className="App">
        <main>
          {
            SpotifyService.instance.accessToken &&
            <>
              <Playlist playlistId="test" />
              <SpotifyWebPlayer 
                token={SpotifyService.instance.accessToken}
                uris={['spotify:track:7GP1ZmPGH1puBliT9S6Fi5']}
              />
            </>            
          }
          {
            SpotifyService.instance.accessToken === undefined &&
            <div>
              <button type="button" onClick={this._onLogin}>Abspielen</button>
            </div>
          }
        </main>
      </div>
    );
  }

  private _onLogin = () => {
    SpotifyService.instance.redirectUserLogin();
  }
}

// export default App;
