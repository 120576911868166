import React from 'react';
import { SpotifyService } from '../../services/SpotifyService';
import { ISongProps } from './ISongProps';
import { ISongState } from './ISongState';

export default class Song extends React.Component<ISongProps, ISongState> {
  public async componentDidMount() {
    document.addEventListener("amx-button-click", e => {
      let position: number = 0;
      switch (Number((e as any).detail?.key)) {
        case 22:
          position = 0;
          break;
        case 9:
          position = 1;
          break;
        case 4:
          position = 2;
          break;
        case 27:
          position = 3;
          break;
        case 17:
          position = 4;
          break;
        case 3:
          position = 5;
          break;
        case 2:
          position = 6;
          break;
        case 11:
          position = 7;
          break;
        case 19:
          position = 8;
          break;
        case 10:
          position = 9;
          break;
      }
      if (this.props.position == position) {
        this._onVote();
      }
    });
  }

  public render(): React.ReactElement<ISongProps> {
    const {
      song
    } = this.props;

    return <div className="amx-song">
      {
        song &&
        <>
          <div className="amx-song-infos">
            <div className="amx-song-infos-title">
              {/* <img src={song?.imageUrl} title={song?.titel}></img> */}
              {song?.titel}
            </div>
            <div>
              {song?.votes} Votes
            </div>
          </div>
          <div className="amx-song-votes">
            <button type="button" onClick={this._onVote}>Vote</button>
          </div>
        </>
      }

    </div>;
  }

  private _onVote = async (): Promise<void> => {
    await SpotifyService.instance.voteUp(this.props.song);
    this.props.onVoted();
  }
}