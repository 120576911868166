import { Constants } from '../common/Constants';
import { IPlaylist } from '../model/IPlaylist';
import { Response, Request, newHttpClient } from 'typescript-http-client'
import { ISong } from '../model/ISong';

export class SpotifyService {
  /**
   * speichert die aktuelle Instanz des Services
   */
  private static _instance: SpotifyService;

  public accessToken?: string;

  /**
   * liefert die aktuelle Instanz
   */
  public static get instance(): SpotifyService {
    if (SpotifyService._instance === undefined) {
      SpotifyService._instance = new SpotifyService();
    }

    return SpotifyService._instance;
  }

  public getPlaylist = async (playlistId: string): Promise<IPlaylist> => {
    // Get a new client
    const client = newHttpClient()

    // Build the request
    const request = new Request(`${Constants.ServerUrl}/Spotify/GetPlaylist`);

    // Execute the request and get the response body as a string
    const playlist: IPlaylist = await client.execute<IPlaylist>(request);

    return playlist;
  };

  public redirectUserLogin = () => {
    const redirect_uri: string = window.location.origin;
    const state: string = "0123456789012345";
      
    window.location.href = `https://accounts.spotify.com/authorize?response_type=code&client_id=${Constants.SpotifyClientId}&scope=${Constants.SpotifyScopes}&redirect_uri=${redirect_uri}&state=${state}`;
  }

  public loadAccessTokens = async (code: string): Promise<void> => {
    // Get a new client
    const client = newHttpClient()

    // Build the request
    const request = new Request(`${Constants.ServerUrl}/Spotify/GetAccessToken/${code}/werdiesliestkannlesen`, { responseType: 'text' });

    // Execute the request and get the response body as a string
    this.accessToken = await client.execute<string>(request);
  }

  public voteUp = async (song: ISong): Promise<void> => {
    // Get a new client
    const client = newHttpClient();

    // Build the request
    const request = new Request(`${Constants.ServerUrl}/Spotify/VoteUp/${song.spotifyId}`);

    // Execute the request and get the response body as a string
    await client.execute<void>(request);
  }

  public calculatePlaylist = async (): Promise<void> => {
    // Get a new client
    const client = newHttpClient();

    // Build the request
    const request = new Request(`${Constants.ServerUrl}/Spotify/CalculatePlaylist`);

    // Execute the request and get the response body as a string
    await client.execute<void>(request);
  }
}
