import React from 'react';
import * as linq from 'linq';
import { IPlaylistState } from './IPlaylistState';
import { IPlaylistProps } from './IPlaylistProps';
import { SpotifyService } from '../../services/SpotifyService';
import { IPlaylist } from '../../model/IPlaylist';
import Song from '../Song/Song';

export default class Playlist extends React.Component<IPlaylistProps, IPlaylistState> {
  constructor(props: IPlaylistProps) {
    super(props);

    this.state = {
      playlist: undefined
    };
  }

  public async componentDidMount() {
    console.log(`componentDidMount - start - ${new Date()}`);
    await this.loadData();

    this.loadDataRecursive();
    console.log(`componentDidMount - end - ${new Date()}`);
  }

  public render(): React.ReactElement<IPlaylistProps> {
    const {
      playlist
    } = this.state;

    return <div className="amx-playlist">
      {
        playlist &&
        <>
          <div>
            <img className="amx-playlist-image" src={playlist?.imageUrl} title={playlist?.titel}></img>
            <span className="amx-playlist-title">

              {playlist?.titel}

            </span>
          </div>
          <div>
            {
              linq.default.from(playlist.songs)
                .orderByDescending(s => s.votes)
                .select((s, i) => <Song key={s.spotifyId} song={s} onVoted={this._onVoted} position={i} />)
            }
          </div>
        </>
      }

    </div>;
  }

  private _onVoted = async (): Promise<void> => {
    await this.loadData();
  }

  private loadDataRecursive = async (): Promise<void> => {
    console.log(`loadDataRecursive - start - ${new Date()}`);
    window.setTimeout(async () => {
      console.log(`loadDataRecursive - setTimeout - start - ${new Date()}`);
      await this.loadData();
      await this.loadDataRecursive();
    }, 10000);
  }

  private loadData = async (): Promise<void> => {
    console.log(`loadData - start - ${new Date()}`);
    await SpotifyService.instance.calculatePlaylist();
    const playlist: IPlaylist = await SpotifyService.instance.getPlaylist(this.props.playlistId);

    this.setState({
      playlist
    });
    console.log(`loadData - end - ${new Date()}`);
  }
}