export class Constants {
  public static get SpotifyClientId(): string {
    return "e5d65dc9837a45fe832e4f2cbf09c3ef";
  }

  public static get SpotifyScopes(): string {
    return "ugc-image-upload user-read-playback-state user-modify-playback-state user-read-currently-playing app-remote-control streaming playlist-read-private playlist-read-collaborative playlist-modify-private playlist-modify-public user-follow-modify user-follow-read user-read-playback-position user-top-read user-read-recently-played user-library-modify user-library-read user-read-email user-read-private";
  }

  public static get ServerUrl(): string {
    return "https://paddimaeschienservice.azurewebsites.net";
  }

  public static get  LightControlUrl(): string {
    return "http://192.168.43.117:9090/set_dmx";
  }

  public static get WhiteChannels(): number[] {
    return [
      5,6,7,8,13,18,21,22,23
    ];
  }
  public static get RedChannels(): number[] {
    return [
      5,10,15,21
    ];
  }

  public static get GreenChannels(): number[]{
    return [
      6,11,16,22
    ]
  }
  public static get BlueChannels(): number[]{
    return [
      7,12,17,23
    ]
  }

  public static get YellowChannels(): number[]{
    return [
      6,7,14,19,22,23
    ]
  }

  public static get MaterChannels(): number[] {
    return [
      4,9
    ]
  }
}